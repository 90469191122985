import { useEffect, useState } from 'react';

type LaravelStats = {
    tinder_messages: number;
    bumble_messages: number;
    onlyfans_messages: number;
    total_screenshots: number;
    total_convos: number;
    // total_users: number;
    good_convos?: number;
};

// {"tinder_messages":81180,"bumble_messages":9446,"onlyfans_messages":1015796,"total_screenshots":2644,"total_convos":65220,"total_users":59}

export type LocationStat = {
    country: string;
    city: string;
    count: string;
    app: string;
    latitude: string;
    longitude: string;
};

const defaultStats: LaravelStats = {
    tinder_messages: 0,
    bumble_messages: 0,
    onlyfans_messages: 0,
    total_screenshots: 0,
    total_convos: 0,
    // total_users: 0,
    // good_convos: 0,
};

// ToDo: move in her the worker stats (cloudflare analytics engine) too
export function useStats() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [studioStats, setStudioStats] = useState<LaravelStats>(defaultStats);
    const [usageStats, setUsageStats] = useState<{
        data: LocationStat[];
        me: LocationStat;
    }>();

    async function fetchDataFromAPI() {
        const response = await fetch('https://turbo.dating.studio/stats');
        const data = (await response.json()) as {
            data: LocationStat[];
            me: LocationStat;
        };
        setUsageStats(data);
    }

    async function fetchDataFromStudio() {
        const data = await fetch(
            'https://app.dating.studio/api/public/stats',
        ).then((res) => res.json() as Promise<LaravelStats>);
        setStudioStats(data);
    }

    useEffect(() => {
        Promise.all([fetchDataFromAPI(), fetchDataFromStudio()]).then(() => {
            setIsLoaded(true);
        });
    }, []);

    const totalCities = usageStats?.data.reduce((acc, point) => {
        if (!acc.includes(point.city)) {
            acc.push(point.city);
        }
        return acc;
    }, [] as string[]).length;

    return {
        isLoaded,
        studioStats,
        usageStats,
        totalCities,
    };
}
